
// Dark sidebar
$sidebar-dark-bg: pink!important;
$sidebar-dark-hover-bg: rgba(255, 255, 255, .1) !default;
$sidebar-dark-color: #c2c7d0 !default;
$sidebar-dark-hover-color: $white !default;
$sidebar-dark-active-color: $white !default;
$sidebar-dark-submenu-bg: transparent !default;
$sidebar-dark-submenu-color: #c2c7d0 !default;
$sidebar-dark-submenu-hover-color: $white !default;
$sidebar-dark-submenu-hover-bg: $sidebar-dark-hover-bg !default;
$sidebar-dark-submenu-active-color: $sidebar-dark-bg !default;
$sidebar-dark-submenu-active-bg: rgba(255, 255, 255, .9) !default;

// .sidebar-dark-primary {
//     background-color: $sidebar-dark-bg;
// }

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 15px!important;
    padding-left: 15px!important;
}
app-manage-role-presentation, app-manage-user, app-manage-email-smtp-setting {
    .m-top-10{
        margin-bottom: 20px;
        .btn {
            height: 40px;
            padding: 5px 15px!important;
            font-size: 16px!important;
         }
    } 
    
}
.mat-dialog-actions, app-manage-action, app-common-dialog, mat-dialog-container {
    .btn {
        height: 40px;
        padding: 5px 15px!important;
        font-size: 16px!important;
     }
}
app-email-template-manage{
    .btn {
        height: 40px;
        padding: 5px 15px!important;
        font-size: 16px!important;
     }
}
app-role-list, app-email-smtp-setting-list, app-email-template-list, app-action-list-presentation, app-page-list-presentation{
    table{
        button, .btn-success {
            background-color: transparent!important;
            background: none!important;
            border: none!important;
            color: #007bff !important;
            display: inline-flex!important;
            flex-direction: column!important;
            align-items: center!important;
        }
        .btn-success:hover{
            background-color: transparent!important;
        }
        .btn-danger{
            color: #dc3545!important;
        }
        .btn-success {
            background-color: transparent!important;
        }
    }
  
}
.btn-outline-primary{
    
    color: #5065A2!important;
    border-color: #5065A2!important;
    &:hover{
        color: #fff!important;
        background-color: #001b69!important;
        border-color: #001b69!important;
    }
    &:active{
        color: #fff!important;
        background-color: #001b69!important;
        border-color: #001b69!important;
    }
    &:focus{
        background: none!important; 
        color: #001b69!important;
    }
}
.btn{
    .d-sm-inline{
        color: #000!important;
    }
}
.btn-big {
    height: 40px;
    padding: 5px 15px!important;
    font-size: 16px!important;
}
.btn-default{
 &:hover{
    border-color: #777!important;
 }
}
.mat-form-field-label{
    color: #000!important;
    font-weight: normal!important;
}
.form-group label{
    font-weight: normal!important;
}
.NgxEditor__Wrapper, .form-control, .btn-sidebar{
    border-color: #777!important;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: #777!important;
}

.font-12 {
	font-size: 12px;
 }
     .font-13 {
		font-size: 13px;
 }
 .font-14 {
	font-size: 14px;
 }
  
   .font-10 {
	font-size: 10px;
 }
  
  .font-18 {
	font-size: 18px; 
}
  
   .font-20 {
	font-size: 20px;
 }
	 .font-24 {
		font-size: 24px;
 }
 .bold-600{
    font-weight: 600;
 }

/*buttons overide*/
mat-dialog-container, app-app-settings-manage, app-manage-action, app-common-dialog, app-manage-page{
    .btn-success{
        i{
            display: none;
        }
    }
    .btn-danger{
        background-color: #fff!important;
        color: #000!important;
        border-color: #777!important;
        &:hover{
            background-color: #f2f2f2!important;
        }
        i{
            display: none;
        }
    }
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #243E8B !important;
}
.btn-success {
    color: #fff;
    background-color: #5065A2!important;
    border-color: #5065A2!important;
    box-shadow: none;
    &:hover{
        background-color: #001b69 !important;
    }
    .fa-save{
display: none;
    }
}
button.btn-danger[ng-reflect-router-link], .btn-outline-secondary{
    background-color: #c8ced3 !important;
    color: #000!important;
    border-color: #c8ced3 !important;
    &:hover{
        background-color: #b3bbc2!important;
    }
    i{
        display: none;
    }
}

 .logo{
    padding: 0px 15px;
    img{
        width: 100%;
        max-width: 180px;
        margin-bottom: 5px;
        margin-top: 10px;
    }
 }
 .loading-shade {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 56px;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.8);
    height: 100%;
    svg{
        width: 70px!important;
        height: 70px!important;
    }
}
.nav-sidebar .menu-is-opening>.nav-link i.right, .nav-sidebar .menu-is-opening>.nav-link svg.right, .nav-sidebar .menu-open>.nav-link i.right, .nav-sidebar .menu-open>.nav-link svg.right {
    transform: rotate(90deg)!important;
}
.table-header{
   th{
    background: none!important;
   }
    th input{
        width: 100%!important;
    }
}
.box-body{
    padding: 10px 15px!important;
}
.NgxEditor__Wrapper{
    min-height: 150px;
}
.table{
    font-size: 14px;
    .text-url{
        white-space: normal;
        word-break: break-all;
        width: 160px;
        display: flex;
    }
    .m-date{
        color: #000;
        font-size: 12px;
        margin-bottom: 5px;
    }
    .m-title {
        font-size: 16px;
        font-weight: bold;
        margin-left: 5px;
    }
}
.table th,
.table td {
  padding: 8px  10px!important;
  vertical-align: top;
  border-top: 1px solid #d2d2d2;
}

.table thead tr th {
    font-weight: 600!important;
    color: #000!important;
    background-color: #ccc;
}
.table-container{
    overflow: initial !important;
    max-height: initial!important;
}
.actions-btn {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    i{
        font-size: 16px;
    }
}
.h4, h4 {
    font-size: 18px!important;
    margin-bottom: 0px!important;
}
table .badge{
    font-size: 12px!important;
    font-weight: normal!important;
    padding: 5px 10px!important;
}

.group-t-bg-heading{
    background-color: #f2f2f2!important;
}
.orderlink-wraper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    li {
        display: inline-block;
        margin: 0 20px 0 0;
    }
    label {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        cursor: pointer;
    }
    .button-link {
        display: flex;
        align-items: center;
    }
    .badge-info {
        color: #000;
        background-color: #d2d2d2;
        font-weight: 400;
    }
    .button-link {
        background: none;
        border: none;
        color: #777;
        padding: 0;
        display: inline-block;
        text-decoration: none;
        font-size: 14px;
        cursor: pointer;
        font-weight: normal;
    }
    .form-checkbox {
        -webkit-appearance: none;
        appearance: none;
        display: inline-block;
        position: relative;
        background-color: #fff;
        color: #666;
        height: 18px;
        width: 18px;
        border: 0;
        cursor: pointer;
        margin-right: 10px;
        outline: none;
        border: 1px solid #aaa!important;
        border-radius: 0;
        box-shadow: none!important;
    }
    .form-checkbox:checked+.button-link .badge {
        background: #000;
        color: #fff;
    }
    .form-checkbox:checked+.button-link {
        color: #000;
        font-weight: 600!important;
    }
    .form-checkbox:checked {
        background-color: #fff;
    }
    input[type=checkbox], input[type=radio] {
        box-sizing: border-box;
        padding: 0;
    }
    .badge-info {
        margin-left: 5px;
    }
    .form-checkbox:checked:before {
        color: #000;
    }
    .form-checkbox:checked::before {
        position: absolute;
        left: 4px;
        top: 1px;
        content: "⅃";
        transform: rotate(40deg);
        font: 700 12px / 1 "Open Sans", sans-serif;
    }
    .btn-sidebar{
        background-color: #f2f2f2;
        border: 1px solid #aaa;
        color: #1f2d3d;
    }

}
.form-control{
    min-height: 40px;
}
 .custom-b-dropdown{
 .dropdown-toggle {
    background: #fff!important;
    border: 1px solid #777!important;
    display: flex!important;
    align-items: center;
    padding: 4px 12px 4px 8px;
    box-shadow: none!important;
    color: #000!important;
    justify-content: space-between;
    margin-bottom: 5px;
    border-radius: 4px;
    margin-top: 5px;
    font-size: 14px;
    min-height: 40px;
    // font-weight: bold;
}
.dropdown-item{
    color: #000;
    font-size: 14px;
    padding: 8px 15px;
    display: flex;
    align-items: center;
}

 }
 td .custom-b-dropdown{
    .dropdown-toggle{
        min-height: 30px;
    }
 }
 .navbar-nav .dropdown-menu{
    max-height: initial!important;
    overflow-x: auto;
 }
 
 .tournament-logo-i{
    display: flex;
   
 img {
    height: 35px;
    margin-right: 5px;
}
}
.custom-b-dropdown img{
    height: 30px;
    
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.main-tournaments-wrap{
    td  .tournament-logo-i{
        width: 180px;
    }
}
.group-t-bg-heading::after {
    content: "";
    width: 50px;
    height: 3px;
    position: relative;
    bottom: 0;
    left: 0;
    display: flex;
    margin-top: 5px;
    background: #ed0000;
}
.tournament-p-wrap{
    max-width: 600px ;
    width: 100%;
}

 .dropdown-toggle:after {
    display: inline-block!important;
    border-top: 6px solid!important;
    border-right: 6px solid transparent!important;
    border-left: 6px solid transparent!important;
    margin-left: auto!important;
    height: 8px!important;
    width: 8px!important;
    border-color: #000!important;
    border-style: solid!important;
    border-width: 0 1px 1px 0!important;
    -webkit-transform: rotate(45deg)!important;
    transform: rotate(45deg)!important;
    transition: border-width .15s ease-in-out!important;
    margin-top: -3px!important;
}
.content-header{
    color: #000!important;
}
.box.box-primary {
    border-top-color: none!important;
}


.table .m-title{
    white-space: normal;
}

td .mat-form-field-infix{
    width: 140px;
}
th .form-control {
    min-height: 30px;
    font-size: 14px;
    height: 30px;
}
.table-header th input{
    max-width: 250px;
}


.dropdown-with-image{
    .mat-select-min-line{
        padding-left: 45px;
    }
    .mat-select {
        display: inline-block;
        width: 100%;
        outline: none;
        // background-size: contain!important;
        background-size: 35px!important;
        background-repeat: no-repeat!important;
    }
    
}
.date-picker{
    .mat-form-field-infix{
        display: flex;
    }
}
.mat-input-element{
    font-weight: bold;
}

.mat-form-field-infix {
    padding-top: 0px!important;
}
.date-picker .mat-form-field-infix{
    border-top: 0px solid transparent!important;
}
.mat-select {
    font-weight: bold;
}
.date-picker-wrap {
    position: relative;
    .date-icon {
        position: absolute;
        top: 0;
        right: 0px;
    }
    .date-icon *{
        color: #000;
    }
}
// status color pallete 
.statusChangeLink {
    // padding: 8px 13px;
    border-bottom: none;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    margin-bottom: 0!important;
    display: flex;
    text-align: left;
    cursor: pointer;
    align-items: center;
    margin-right: 0;
}
.status-color-scheduled{
    background: #7caafe;
}
.status-color-live{
    background: #ff0000;
}
.status-color-delayed{
    background: #ffd200;
}
.status-color-stopped{
    background: #444444;
}
.status-color-finished{
    background: #2fcb82;
}
.status-color-cancelled{
    background: #ff7171;
}
.status-color-rescheduled{
    background: #0a8ae6;
}

.date-picker{margin-top: 5px;}

.modal-small{
    width: 320px;
    .modal-footer {
        margin-bottom: -8px;
    }
}
.modal-middle {
    width: 550px;
    .modal-footer {
        margin-bottom: -8px;
    }
}

.user-image-bg {
    width: 25px;
    height: 25px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-color: #f2f2f2;
    border: 1px solid #d2d2d2;
    margin-right: 10px;
}
.sidebar-img{
    width: 33px;
    height:33px;
    margin-right: 0px;
}
.h-dropdown-img{
    width: 90px;
    height:90px;
    margin: auto;
}

table {
    width: 100%;
    border: 1px solid #d2d2d2;
}
.table-container{
    border: none;
}

.dropdown-menu{
    transition: none;
    width: 100%;
}
.content-wrapper {
    padding-top: 15px;
}
.nav-treeview a {
    padding-left: 45px!important;
}
.new-datepicker-m{
    .mat-form-field-underline{
        display: none;
    }
    .form-control{
        padding: 0rem 0.75rem;
        font-weight: 500!important;
    }
}

.custom-b-dropdown .dropdown-item{
    white-space: normal;
}

.time-picker-n-wrap .dropdown-toggle::after {
    display: none!important;
}
.time-picker-n-wrap .dropdown {
    cursor: pointer;
}
.time-icon {
    font-size: 19px;
}
.time-picker-n-wrap {
    .btn-link {
        color: #000!important;
        
    }
    i{
        color: #777!important;
        
    }
}
.mat-button-wrapper svg{
fill:#777!important;
}
.custom-b-dropdown{
    .dropdown-menu {
        overflow-x: hidden;
        max-height: 340px;
        overflow-y: auto;
    }
    button{
        white-space: normal;
        text-align: left;
    }

}
.custom-b-dropdown button, .custom-b-dropdown .dropdown-toggle{
    font-size: 16px!important;
    font-weight: 600;
    color: #000;
}
.main-header.navbar{
    position: sticky!important;
    top: 0;
}
.iti{
    display: flex!important;
}
body:not(.layout-fixed) .main-sidebar {
    position: fixed!important;
}
aside {
    .sidebar {
        nav{
            height: calc(100vh - 140px);
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}
.toast-container .ngx-toastr { 
    background-position: 15px 15px!important;
}
.login100-form{
    border-radius: 6px;
}
.sticky-element{
    position: sticky;
    top: 56px;
    z-index: 2;
}
.mat-spinner-color-white   circle {
    stroke: #fff!important;
}
.mat-tab-group {
    font-family: inherit;
}
.color-7{
    color: #777;
}
.color-6{
    color: #666;
}
.mat-tab-group.mat-primary .mat-ink-bar{
    background-color: #5065A2 ;
}
.mat-tab-body-content {
    height: auto!important;
    overflow: inherit !important;
}
.dropdown-item:active {
    color: #000!important;
    text-decoration: none;
     background-color: #f8f9fa!important; 
}
.mat-tab-label-active {
    font-weight: bold!important;
    color: #000!important;
    font-family: inherit;
    opacity: 1!important;
}
.mat-tab-header {
    display: flex!important;
    overflow: hidden!important;
    position: sticky!important;
    flex-shrink: 0!important;
    top: 57px;
    z-index: 11;
    background: #fff;
}
.flex-1{
    flex: 1;
}
.autocomplete-list-add-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    .autocomplet-tag {
        display: flex;
        /* cursor: pointer; */
        border: 1px solid #777;
        border-radius: 6px;
        padding: 4px 10px 4px 10px;
        background-color: #f2f2f2;
        margin-bottom: 10px;
        margin-right: 1rem;
        font-size: 14px;
        align-items: center;
        font-weight: 600;
        cursor: pointer;
        &:hover{
            background-color: #ebe8e8;
        }
    }
    .tag-image {
        width: 25px;
        height: 25px;
        background-size: cover;
        background-position: center;
        border-radius: 0px;
        background-repeat: no-repeat;
        background-color: #f2f2f2;
        border: none;
        margin-right: 10px;
        display: flex;
    }
    .tag-close {
        padding: 0px;
        cursor: pointer;
        margin-left: 10px;
        width: 20px;
        text-align: center;
    }
}
 .mat-option-text{
    width: 100%!important;
}
// .user-a-height {
//     overflow: auto;
//     max-height: calc(100vh - 450px);
// }
.group-t-heading{
    font-weight: bold;
}
.s-active{
    background-color: rgba(0,0,0,.1)!important;
    color: #000!important;
}
.content-wrapper .form-control-sidebar, .input-group-append {
    display: flex!important;
}
.cdk-overlay-container {
    z-index: 1040!important;
}
.arrow-hide .dropdown-toggle::after{
    display: none!important;
}
 .arrow-hide .dropdown-toggle{
    justify-content: start!important;
}

input.form-control:focus, .mat-focused.form-control, textarea.form-control:focus,  button.dropdown-toggle:focus, .ProseMirror-focused {
    border-color: #66afe9 !important;;

    //  box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25)!important;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6) !important;;
}
.NgxEditor__Wrapper .NgxEditor{
    border-radius: 4px!important;
}
app-match-list, app-tournament-list, app-app-user-list, app-manage-tournament, app-past-match{
    .input-group {
        position: relative;
        width: 100%;
        min-width: 300px;
        .form-control{
            border-radius: 3px!important;
            padding: 0.375rem 0.75rem 0.375rem 2.8rem;
        }
        .input-group-append{
            position: absolute;
            left: 3px;
            z-index: 3;
            top: 1px;
            border: none;
            height: calc(100% - 2px);
 button{
    border: none;
    // border-left: 1px solid;
    background-color: transparent;
 }
        }
    }
}
ngx-image{
    .NgxEditor__Popup{
        left: 0;
    }
}

// app-manage-tournament{
//     .mat-tab-body-wrapper{
//    //     z-index: 11;
//         overflow: initial;
//     }
//     .mat-tab-body.mat-tab-body-active{
//         overflow:initial;
//     }
// }