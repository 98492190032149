@media screen and (max-width: 1200px) {
    .table-responsive{
        height: calc(100vh - 236px);
        overflow: auto!important;
    }
    .sticky-element {
        position: sticky;
        top: 0px!important;
        z-index: 2;
    }
    .c-h-team{
        position: relative;
        overflow: auto;
        width: 100%;
        overflow-x: auto!important;
       // max-width: 1000px!important;
        table{
            input, .dropdown-toggle{
                min-width: 220px;
    
            }
    
        }
        th, td{white-space: nowrap!important;}
    }
  }

  @media screen and (max-width: 767px) {
    .modal-middle {
        max-width: 550px;
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
.m-list-wrap{
    .m-country{
        width: 100%;
    }
}
}